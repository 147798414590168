import * as types from './types';

export const globalTitle = title => dispatch => (
  dispatch({
    type: types.GLOBAL_TITLE,
    value: title
  })
);

export const globalType = type => dispatch => (
  dispatch({
    type: types.GLOBAL_TYPE,
    value: type
  })
);

export const globalEdited = val => dispatch => (
  dispatch({
    type: types.GLOBAL_EDITED,
    value: val
  })
);

export const globalImages = data => dispatch => (
  dispatch({
    type: types.GLOBAL_IMAGES,
    value: data
  })
);

export const removeGlobalImages = data => dispatch => (
  dispatch({
    type: types.REMOVE_GLOBAL_IMAGE,
    value: data
  })
);

export const activeForm = form => dispatch => (
  dispatch({
    type: types.UPDATE_FORM_ID,
    value: form
  })
);

export const globalHelpVisible = val => dispatch => (
  dispatch({
    type: types.GLOBAL_HELP_VISIBLE,
    value: val
  })
);

export const globalHelpPositionX = val => dispatch => (
  dispatch({
    type: types.GLOBAL_HELP_POSITION_X,
    value: val
  })
);

export const globalHelpType = type => dispatch => (
  dispatch({
    type: types.GLOBAL_HELP_TYPE,
    value: type
  })
)

export const setModulesCollapsed = val => dispatch => (
  dispatch({
    type: types.SET_MODULES_COLLAPSED,
    value: val
  })
);
