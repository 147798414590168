import React from 'react';
import styled, { css } from 'styled-components';

const ButtonReset = css`
  background: transparent;
  border: none;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font: inherit;
  letter-spacing: .24em;
  /* - .4rem to account for border */
  line-height: calc(4em - .4rem);
  margin: 0;
  max-height: 4.7rem;
  opacity: 1;
  overflow: visible;
  padding: 0 3.5rem;
  text-transform: uppercase;
  transition-property: opacity;
  width: auto;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;

  :active {
    outline: 0;
  }

  :hover {
    opacity: 0.75;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: .5;
  }
`;

const StyledButton = styled.button`
  ${ButtonReset}
  font-family: ${props => props.theme.font.caption};
  font-size: ${props => props.theme.modularScale.small};
  text-transform: uppercase;
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  background-color: ${props => props.theme.color.brand};
  color: ${props => props.theme.color.white};
`;

const Button = props => (
  <StyledButton {...props}>
    {props.children}
  </StyledButton>
);

export default Button;
