import * as types from './types';

export const signIn = userData => dispatch => (
  dispatch({
    type: types.USER_SIGNIN,
    user: userData.user,
    jwt: userData.jwt
  })
);

export const signOut = userData => dispatch => (
  dispatch({
    type: types.USER_SIGNOUT
  })
);

export const textUpdate = text => dispatch => (
  dispatch({
    type: types.TEXT_INPUT_UPDATE,
    value: text
  })
);

export const userUpdate = text => dispatch => (
  dispatch({
    type: types.USER_INPUT_UPDATE,
    value: text
  })
);

export const emailUpdate = text => dispatch => (
  dispatch({
    type: types.EMAIL_INPUT_UPDATE,
    value: text
  })
);

export const passUpdate = text => dispatch => (
  dispatch({
    type: types.PASSWORD_INPUT_UPDATE,
    value: text
  })
);

export const passBoolean = boolVal => dispatch => (
  dispatch({
    type: types.PASSWORD_BOOLEAN_UPDATE,
    value: boolVal
  })
);
