import axios from 'axios';
// import * as Sentry from '@sentry/node';

const AuthApi = {
  login(uri, formData) {
    const { identifier, password } = formData;
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    return axios.post(`${uri}/api/auth/local`,
      {
        identifier,
        password
      },
      axiosConfig)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        const errorData = error.response ? error.response : error.request;
       // Sentry.captureException(error);
        return errorData;
      });
  },
  register(uri, user) {
    const axiosConfig = {
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    };
    return axios.post(`${uri}/api/auth/local/register`, {
      ...user
    },
    axiosConfig)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      const errorData = error.response ? error.response : error.request;
     // Sentry.captureException(error);
      return errorData;
    });
  }
};

export default AuthApi;
