import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from './button';
import { globalHelpVisible, globalHelpPositionX, globalHelpType } from '../../../store/global/action';

const ignoreEnterKey = (event) => {
  const { currentTarget } = event;
  // allow enter to be used to login/register
  const loginIds = { 'email': true, 'pass': true, 'username': true };
  if (event.keyCode === 13 && !loginIds[currentTarget.id]) event.preventDefault();
};

const addLineBreakOnEnter = (event) => {
    const { currentTarget } = event;
    // adds a line break when user presses enter
  if (event.keyCode === 13 && currentTarget.selectionStart) {
    const { selectionStart, selectionEnd, value } = currentTarget;
    // get the textarea's value before and after cursor location
    const valueBefore = value.substring(0, selectionStart);
    const valueAfter = value.substring(selectionEnd, value.length);
    const lineBreak = '<br />';

    // add the line break html
    const valueWithBreak = valueBefore + lineBreak + valueAfter;
    const newCursorPos = selectionStart + lineBreak.length;

    // set the value of textarea with the added line break
    // set the cursor to position after added line break
    currentTarget.value = valueWithBreak;
    currentTarget.setSelectionRange(newCursorPos, newCursorPos);
  }
};

const LabelWrapper = styled('div')`
  font-family: ${(props) => props.theme.font.caption};
  font-size: ${(props) => props.theme.modularScale.small};
  color: ${(props) => props.theme.color.brandLight};
  line-height: 1.2rem;
  max-width: 10rem;
  display: flex;
  flex-direction: column;
  ${(props) => props.emptyAltText && css`
    color: ${(props) => props.theme.color.brandError};
  `}
`;

const StyledInput = styled('input')`
  font-family: ${(props) => props.theme.font.serif};
  color: ${(props) => props.theme.color.brand};
  max-height: 3rem;
  ${(props) => (props.hasError || props.emptyAltText) && css`
    outline: solid 2px ${(props) => props.theme.color.brandError};
  `}
  ${(props) => props.type === 'checkbox'
    ? `appearance: none;
    height:2rem;
    width: 2rem;
    margin: 0 0 0 1rem;
    position: relative;
    ${props.defaultChecked
    ? `background: white;
    border-left: 1px solid ${props.theme.color.brand};
    border-bottom: 1px solid ${props.theme.color.brand};
    :after {
      content: '✔︎';
      position: absolute;
      top: -2rem;
      font-size: 3rem;
    }`
    : `background: ${props.theme.color.brandLight};
    border-left: 1px solid ${props.theme.color.brand};
    border-bottom: 1px solid ${props.theme.color.brand};`
}`
    : 'padding: 0 1rem;'}`;

const StyledTextArea = styled('textarea')`
  font-family: ${(props) => props.theme.font.serif};
  color: ${(props) => props.theme.color.brand};
  resize: vertical;
  grid-row: span 5;
  ${(props) => props.hasError && css`
    outline: solid 2px ${(props) => props.theme.color.brandError};
  `}
`;

const StyledLabel = styled('label')`
  ${(props) => props.type === 'checkbox' ? 'line-height: 1.2rem;' : ''}
  ${(props) => props.altText && css`
    line-height: 1.2rem;
    max-width: 10rem;
  `}
  font-family: ${(props) => props.theme.font.caption};
  font-size: ${(props) => props.theme.modularScale.small};
  text-transform: uppercase;
  color: ${(props) => props.theme.color.brandLight};
  padding: 0 0 0 1rem;
  text-align: right;
  ${(props) => (props.hasError || props.emptyAltText) && css`
    color: ${(props) => props.theme.color.brandError};
  `}
`;

const HelpButton = styled(Button)`
  color: inherit;
  height: auto;
  width: auto;
  line-height: inherit;
  text-align: right;
  background: none;
  padding: 0;
  text-decoration: underline;
  letter-spacing: normal;
  margin-top: 0.25rem;
`;

const labelProps = (props) => {
  const { value, id, ...newProps } = props;
  return newProps;
};

const UserInput = (props) => {
  const inputRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const emptyAltText = props.altText && props.value?.trim() === '';

  function validate(e) {
    const error = props.validationFunction(e);
    if (error) {
      setErrorMessage(error);
    } else {
      setErrorMessage('');
    }
  }

  const handleHelp = () => {
    const { current } = inputRef;
    if (current) {
      const { left } = current.getBoundingClientRect()
      const { innerWidth } = global.window;
      const position = (innerWidth / 2) < left ? 'left' : 'right';
      props.globalHelpPositionX(position);
      current.focus();
    }
    props.globalHelpType('altText')
    props.globalHelpVisible(true);
  }

  return (
    <>
      {
        props.altText ?
          <LabelWrapper
            hasError={!!errorMessage}
            emptyAltText={emptyAltText}
          >
            <StyledLabel
              {...labelProps(props)}
              htmlFor={props.id}
              hasError={!!errorMessage}
              emptyAltText={emptyAltText}
            >
              {props.text}
            </StyledLabel>
            <HelpButton
              type="button"
              onClick={handleHelp}
              data-test-id={`guidelines-${props.id}`}
            >
              Guidelines Here
            </HelpButton>
        </LabelWrapper> :
        <StyledLabel
          {...labelProps(props)}
          htmlFor={props.id}
          hasError={!!errorMessage}
        >
          {props.text}
        </StyledLabel>
      }
      {props.type === 'textarea'
        ? (
          <StyledTextArea
            {...props}
            id={props.id}
            type={props.type}
            value={props.value}
            onChange={(e) => {
              validate(e);
              props.changed(e);
            }}
            onBlur={props.blurred}
            onKeyDown={addLineBreakOnEnter}
            hasError={!!errorMessage}
          />
        )
        : (
          <StyledInput
            {...props}
            ref={inputRef}
            id={props.id}
            type={props.type}
            value={props.value}
            onChange={(e) => {
              validate(e);
              props.changed(e);
            }}
            onBlur={props.blurred}
            onKeyDown={ignoreEnterKey}
            hasError={!!errorMessage}
            emptyAltText={emptyAltText}
          />
        )}
    </>
  );
};

UserInput.defaultProps = {
  value: '',
  text: '',
  changed: () => {},
  blurred: () => {},
  validationFunction: () => {}
};

UserInput.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  text: PropTypes.string,
  changed: PropTypes.func,
  blurred: PropTypes.func,
  validationFunction: PropTypes.func,
  globalHelpVisible: PropTypes.func.isRequired,
  globalHelpPositionX: PropTypes.func.isRequired,
  globalHelpType: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch) => ({
  globalHelpVisible: (val) => dispatch(globalHelpVisible(val)),
  globalHelpPositionX: (val) => dispatch(globalHelpPositionX(val)),
  globalHelpType: (type) => dispatch(globalHelpType(type))
});

export default connect(null, mapDispatchToProps)(UserInput);
